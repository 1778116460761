<div class="mat-checkbox-inline">
  <mat-checkbox
    [formControl]="formControl"
    [indeterminate]="indeterminate"
  >
    <div class="form-checkbox__content">
      <ng-content></ng-content>
      <span>{{ label }}</span>
    </div>
  </mat-checkbox>
</div>
