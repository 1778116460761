import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule,
    ReactiveFormsModule,
  ],
})

export class FormCheckboxComponent implements ControlValueAccessor {
  @Input() formControl: FormControl<boolean | null>;
  @Input() label: string;
  @Input() indeterminate = false;

  registerOnChange(): void {}

  registerOnTouched(): void {}

  writeValue(): void {}
}
